import { useContext, useState, useCallback } from 'react'
import loadSupportedCurrencies from '../services/exchangeRates'
import AuthContext from '../context/AuthContext'

const useSupportedCurrencies = () => {
	const [supportedCurrencies, setSupportedCurrencies] = useState(undefined)
	const [error, setError] = useState(undefined)
	const [isLoading, setIsLoading] = useState(false)

	const { token } = useContext(AuthContext)

	const load = useCallback(async () => {
		setIsLoading(true)
		setError(undefined)
		setSupportedCurrencies(undefined)
		try {
			const list = await loadSupportedCurrencies(token)
			setSupportedCurrencies(
				list.map(({ currencyCode: value, currencyName: label }) => ({
					value,
					label,
				})),
			)
		} catch (error) {
			setError(error)
		} finally {
			setIsLoading(false)
		}
	}, [token])
	return { supportedCurrencies, load, error, isLoading }
}

export default useSupportedCurrencies
