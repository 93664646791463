import { useContext, useState, useCallback } from 'react'
import _ from 'lodash'
import { loadRecommendationsPackages, loadRecommendationsPacks } from '../services/planning'
import AuthContext from '../context/AuthContext'
import { fetchLinkedResources } from '../services/recommendations'
import { isPackagesRequest, isPackRequest } from '../services/packsOrPackages'

export const loadRecommendations = (request, ...args) => {
	let loadRecommendations = _.noop
	if (isPackRequest(request)) {
		loadRecommendations = loadRecommendationsPacks
	} else if (isPackagesRequest(request)) {
		loadRecommendations = loadRecommendationsPackages
	} else {
		throw new Error('Unknown request type')
	}
	return loadRecommendations(request, ...args)
}

const useRecommendations = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [data, setData] = useState(undefined)
	const { token } = useContext(AuthContext)
	const load = useCallback(
		async request => {
			try {
				setError(undefined)
				setData(undefined)
				setIsLoading(true)
				const recommendationsResponse = await loadRecommendations(request, token)
				await fetchLinkedResources(recommendationsResponse.recommendations, token)
				setData(recommendationsResponse)
			} catch (error) {
				setError(error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)
	const clear = useCallback(() => {
		setData(undefined)
		setError(undefined)
	}, [])
	return { data, clear, isLoading, error, load }
}

export default useRecommendations
