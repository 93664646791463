import React, { createContext } from 'react'

export const FulfillersNamesContext = createContext({
	fulfillersIdNameMap: undefined,
})

export const FulfillersNamesProvider = ({ children, value }) => {
	const context = {
		fulfillersIdNameMap: value,
	}

	return <FulfillersNamesContext.Provider value={context}>{children}</FulfillersNamesContext.Provider>
}

export default FulfillersNamesContext
