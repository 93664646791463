import { useState, useMemo } from 'react'
import { memoize } from 'lodash'

const useFormFields = initialValue => {
	const [fields, updateFields] = useState(initialValue)

	const updateFieldsNamespaced = useMemo(
		() => memoize(namespace => value => updateFields(form => ({ ...form, [namespace]: value }))),
		[updateFields],
	)

	return {
		fields,
		updateFields: updateFieldsNamespaced,
	}
}

export default useFormFields
