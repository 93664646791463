import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, ButtonGroup, Tooltip, Snackbar } from '@cimpress/react-components'
import { IconCopyPaste } from '@cimpress-technology/react-streamline-icons'
import 'moment/min/locales'
import copyToClipboard from 'copy-to-clipboard'

import AppTypeSwitcher from './AppTypeSwitcher/AppTypeSwitcher'
import usePath from '../../hooks/usePath'

import styles from './RequestForm.module.css'
import './has-errors-fix.css'

export default function RequestForm({ submitLabel, FormComponent, onSubmit }) {
	const [showError, setShowError] = useState(false)
	const [hasError, setHasError] = useState(false)
	const [request, setRequest] = useState(null)
	const [autoSubmit, setAutoSubmit] = useState(false)
	const [alreadySubmitted, setAlreadySubmitted] = useState(false)

	const { generateUrl } = usePath()
	const [showClipboardSnackbar, setShowClipboardSnackbar] = useState(false)

	const submitForm = useCallback(() => {
		setAlreadySubmitted(true)
		if (hasError) {
			setShowError(true)
		} else {
			onSubmit(request)
		}
	}, [hasError, request, onSubmit])

	const copyRequestToClipboard = useCallback(() => {
		if (hasError) {
			setShowError(true)
		} else {
			const url = generateUrl({ r: JSON.stringify(request) })
			copyToClipboard(url)
			setShowClipboardSnackbar(true)
		}
	}, [hasError, request, generateUrl])

	const onFormChange = useCallback((request, hasError, autoSubmit) => {
		setHasError(hasError)
		setRequest(request)
		setAutoSubmit(autoSubmit)
	}, [])

	useEffect(() => {
		if (autoSubmit && !alreadySubmitted) {
			submitForm()
		}
	}, [autoSubmit, submitForm, alreadySubmitted])
	return (
		<Card className={styles.requestForm}>
			<div className={styles.formInputs}>
				<AppTypeSwitcher />
				<FormComponent onChangeViewType={() => setShowError(false)} showError={showError} onChange={onFormChange} />
			</div>
			<div className={styles.submitRow}>
				<ButtonGroup type="justified">
					<Button type="primary" size="lg" onClick={submitForm} className={styles.submitButton}>
						{submitLabel}
					</Button>
					<Tooltip direction={'top'} contents="Copy request link to clipboard">
						<Snackbar
							show={showClipboardSnackbar}
							status="info"
							delay={1000}
							onHideSnackbar={() => setShowClipboardSnackbar(false)}
						>
							Request URL copied to clipboard
						</Snackbar>
						<Button type="primary" size="lg" onClick={copyRequestToClipboard} className={styles.submitButtonMore}>
							<IconCopyPaste />
						</Button>
					</Tooltip>
				</ButtonGroup>
			</div>
		</Card>
	)
}
