import _ from 'lodash'
import moment from 'moment'
import hash from 'object-hash'

function isValidCostEstimate(costEstimate) {
	return _.get(costEstimate, 'unifiedCurrency.breakdown', []).length > 0
}
function isValidTransitEstimate(transitEstimate) {
	return transitEstimate && transitEstimate.shipDateTime && transitEstimate.deliveryDate
}

function getCostData({ costEstimate, transitEstimate }) {
	if (isValidCostEstimate(costEstimate)) {
		return {
			data: _.get(costEstimate, 'carrierServiceCurrency', costEstimate.unifiedCurrency),
			shipDate: _.get(transitEstimate, 'shipDateTime', 'unknown'),
			hashKey: hash(costEstimate),
		}
	} else {
		return null
	}
}
function getDatesData({ transitEstimate }) {
	if (isValidTransitEstimate(transitEstimate)) {
		return _.pick(transitEstimate, ['shipDateTime', 'deliveryDate'])
	} else {
		return null
	}
}
function addBreakdownToRouteData(breakdowns, { data, shipDate, hashKey }) {
	if (!_.get(breakdowns, hashKey)) {
		breakdowns = {
			[hashKey]: {
				breakdown: data.breakdown,
				shipDates: [shipDate],
			},
		}
	} else {
		breakdowns[hashKey].shipDates.push(shipDate)
	}
	return breakdowns
}
function getExclusionReasonsData(shipment) {
	/**
	 * This is pretty unreadable oneliner, but it is just a preparation for once the exclusion reasons are fixed on shipcalc side.
	 *
	 * Eventually, shipcalc will send the leg number for each exclusion reason, so that we will add it to the correct leg.
	 * Right now, shipcalc sends only the last leg exclusion reasons,
	 *
	 * We return array where each element is array of exclusion reasons for that leg - in our case all arrays but the last one are going to be empty)
	 */
	const shipDate = _.get(shipment.transitEstimate, 'shipDateTime', 'unknown')
	const lastLegExclusionReasons = _.get(shipment, 'exclusionReasons', [])
	return _.fill(
		_.fill(Array(shipment.legs.length), {
			data: [],
			hashKey: hash([]),
			shipDate,
		}),
		{
			data: lastLegExclusionReasons,
			hashKey: hash(lastLegExclusionReasons),
			shipDate,
		},
		shipment.legs.length - 1,
	)
}

function getBiggerCost(first, second) {
	if (_.isNil(first)) {
		return second
	}
	if (_.isNil(second)) {
		return first
	}
	return Math.max(first, second)
}

function getSmallerCost(first, second) {
	if (_.isNil(first)) {
		return second
	}
	if (_.isNil(second)) {
		return first
	}
	return Math.min(first, second)
}

function getEarlierDate(first, second) {
	if (_.isNil(first)) {
		return second
	}
	if (_.isNil(second)) {
		return first
	}
	return moment(first).isBefore(moment(second)) ? first : second
}
function getLaterDate(first, second) {
	if (_.isNil(first)) {
		return second
	}
	if (_.isNil(second)) {
		return first
	}

	return moment(first).isAfter(moment(second)) ? first : second
}

function applyDatesDataToRouteData(routeData, datesData) {
	routeData.shipmentsWithoutDatesData = _.isNil(routeData.shipmentsWithoutDatesData)
		? 0
		: routeData.shipmentsWithoutDatesData
	if (!datesData) {
		return {
			...routeData,
			shipmentsWithoutDatesData: routeData.shipmentsWithoutDatesData + 1,
		}
	} else {
		return {
			...routeData,
			earliestDeliveryDate: getEarlierDate(routeData.earliestDeliveryDate, datesData.deliveryDate),
			latestDeliveryDate: getLaterDate(routeData.latestDeliveryDate, datesData.deliveryDate),
		}
	}
}
function applyExclusionReasonsDataToRouteData(routeData, exclusionReasonsData) {
	for (let i = 0; i < routeData.legs.length; i++) {
		const existingExclusionReasons = _.get(routeData.legs[i], `exclusionReasons.${exclusionReasonsData[i].hashKey}`)
		if (existingExclusionReasons) {
			existingExclusionReasons.shipDates.push(exclusionReasonsData[i].shipDate)
			existingExclusionReasons.count++
		} else {
			_.set(routeData.legs[i], `exclusionReasons.${exclusionReasonsData[i].hashKey}`, {
				data: exclusionReasonsData[i].data,
				shipDates: [exclusionReasonsData[i].shipDate],
				count: 1,
			})
		}
	}
	return routeData
}
function applyCostDataToRouteData(routeData, costData) {
	routeData.shipmentsWithoutCostData = _.isNil(routeData.shipmentsWithoutCostData)
		? 0
		: routeData.shipmentsWithoutCostData

	if (!costData) {
		return {
			...routeData,
			shipmentsWithoutCostData: routeData.shipmentsWithoutCostData + 1,
		}
	} else {
		return {
			...routeData,
			minShippingCost: getSmallerCost(routeData.minShippingCost, costData.data.shipping),
			minTotalCost: getSmallerCost(routeData.minTotalCost, costData.data.total),
			maxShippingCost: getBiggerCost(routeData.maxShippingCost, costData.data.shipping),
			maxTotalCost: getBiggerCost(routeData.maxTotalCost, costData.data.total),
			breakdowns: addBreakdownToRouteData(routeData.breakdowns, costData),
			currencyCode: costData.data.currencyCode,
		}
	}
}
function getShippingFeasibilityProblemRoutes(problems) {
	return problems.map(problem => ({
		id: problem.carrierService,
		isValid: false,
		name: problem.carrierService,
		legs: [
			{
				name: problem.carrierService,
				exclusionReasons: {
					anyKey: {
						data: [
							{
								name: 'Shipping Feasibility Error',
								additionalInformation: problem.message,
							},
						],
					},
				},
			},
		],
	}))
}
export function convertRecommendationsResponseToRouteDiagnostics(recommendationsResponse) {
	let result = Object.values(
		[
			...recommendationsResponse.recommendations.map(plan => {
				plan.isValid = true
				return plan
			}),
			...recommendationsResponse.invalidPlans.map(plan => {
				plan.isValid = false
				return plan
			}),
		].reduce((result, plan) => {
			plan.shipments.forEach(shipment => {
				const routeData = result[shipment.routeId]
				const costData = getCostData(shipment)
				const datesData = getDatesData(shipment)
				const exclusionReasonsData = getExclusionReasonsData(shipment)
				if (!routeData) {
					result[shipment.routeId] = applyCostDataToRouteData(
						applyDatesDataToRouteData(
							applyExclusionReasonsDataToRouteData(
								{
									id: shipment.routeId,
									legs: shipment.legs,
									isValid: plan.isValid,
									routeQuantity: 1,
									name: _.join(
										shipment.legs.map(leg => leg.name),
										' • ',
									),
								},
								exclusionReasonsData,
							),
							datesData,
						),
						costData,
					)
				} else {
					result[shipment.routeId] = applyCostDataToRouteData(
						applyDatesDataToRouteData(
							applyExclusionReasonsDataToRouteData(
								{ ...routeData, isValid: routeData.isValid && plan.isValid }, // if at least 1 plan is invalid, the whole route is invalid
								exclusionReasonsData,
							),
							datesData,
						),
						costData,
					)
					result[shipment.routeId].routeQuantity++
				}
			})
			return result
		}, {}),
	)
	if (_.get(recommendationsResponse, 'shippingFeasibility.status') === 'invalid') {
		result = [
			...result,
			...getShippingFeasibilityProblemRoutes(_.get(recommendationsResponse, 'shippingFeasibility.problems', [])),
		]
	}
	return result
}
