import formStyles from '../RequestForm.module.css'
import { TextField } from '@cimpress/react-components'
import React from 'react'

export function TimeInput({ value, required, onChange, errors }) {
	return (
		<TextField
			className={formStyles.noBottomMargin}
			bsStyle={errors ? 'error' : ''}
			value={value}
			onChange={result => {
				onChange(result.target.value)
			}}
			helpText={errors ? "Valid time examples '12:30 PM' or '23:30'." : ''}
			required={required}
		/>
	)
}
