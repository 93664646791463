import React, { createContext } from 'react'
import useFulfillmentLocations from '../hooks/useFulfillmentLocations'

export const FulfillmentLocationsContext = createContext({
	error: undefined,
	isLoading: false,
	fulfillmentLocations: undefined,
	load: async () => {
		throw new Error('FulfillmentLocationsContext not defined')
	},
})

export const FulfillmentLocationsProvider = ({ children, value }) => {
	const { load, error, isLoading, fulfillmentLocations } = useFulfillmentLocations()

	const context = {
		fulfillmentLocations: value || fulfillmentLocations,
		error,
		isLoading,
		load,
	}

	return <FulfillmentLocationsContext.Provider value={context}>{children}</FulfillmentLocationsContext.Provider>
}

export default FulfillmentLocationsContext
