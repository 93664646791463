import React, { useContext } from 'react'
import { TextField, Checkbox } from '@cimpress/react-components'
import IconClose from '@cimpress-technology/react-streamline-icons/lib/IconClose'
import { ValueInput } from '../../ValueInput/ValueInput'
import SelectValue from '../../../SelectValue/SelectValue'

import LocaleContext from '../../../../context/LocaleContext'
import { lengthUnits } from '../../../../constants/lengthUnits'
import { weightUnits } from '../../../../constants/weightUnits'

import styles from '../ShipmentInput.module.css'
import formStyles from '../../RequestForm.module.css'

function hasDimensionsError(errors) {
	return !!(errors && (errors.dimension1 || errors.dimension2 || errors.dimension3 || errors.weight))
}

function isAcceptablePositiveNumberInput(str) {
	if (str === '') {
		return true
	}
	const val = parseFloat(str)
	if (isNaN(val) || val < 0) {
		return false
	}
	return true
}

export default function PackageInput({ rank, value, onChange, onRemove, errors, ...props }) {
	const { currency: defaultCurrency } = useContext(LocaleContext)
	return (
		<>
			<div
				className={[
					props.className,
					'package-dimensions-input',
					'form-group',
					hasDimensionsError(errors) ? 'has-error' : '',
				].join(' ')}
			>
				<div className={styles.gridItem}>
					{!props.singleRow ? <span className={styles.numeration}>{rank + 1 + '.'}</span> : null}
					<div>
						<div className={styles.gridItem}>
							<TextField
								name="height"
								type="number"
								min="0"
								value={value.dimension1}
								className={styles.dimensionInput}
								label="Dim. 1"
								onChange={({ target }) =>
									isAcceptablePositiveNumberInput(target.value) && onChange({ ...value, dimension1: target.value })
								}
							/>
							<span className={`text-center ${styles.dimensionMultiplier}`}>×</span>
							<TextField
								name="width"
								type="number"
								min="0"
								value={value.dimension2}
								className={styles.dimensionInput}
								label="Dim. 2"
								onChange={({ target }) =>
									isAcceptablePositiveNumberInput(target.value) && onChange({ ...value, dimension2: target.value })
								}
							/>
							<span className={`text-center ${styles.dimensionMultiplier}`}>×</span>
							<TextField
								name="length"
								type="number"
								min="0"
								value={value.dimension3}
								className={styles.dimensionInput}
								label="Dim. 3"
								onChange={({ target }) =>
									isAcceptablePositiveNumberInput(target.value) && onChange({ ...value, dimension3: target.value })
								}
							/>
							<span className={styles.unitSeparator}>&nbsp;</span>
							<SelectValue
								className={`${styles.noLabelSelect} ${styles.measurementUnit}`}
								value={value.lengthUnit}
								options={lengthUnits}
								onChange={lengthUnit => onChange({ ...value, lengthUnit })}
							/>
						</div>
						<div className={styles.gridItem}>
							<TextField
								name="weight"
								type="number"
								min="0"
								step=".01"
								value={value.weight}
								className={`${styles.dimensionInput} ${formStyles.noBottomMargin}`}
								label="Weight"
								onChange={({ target }) =>
									isAcceptablePositiveNumberInput(target.value) && onChange({ ...value, weight: target.value })
								}
							/>
							<span className={styles.unitSeparator}>&nbsp;</span>
							<SelectValue
								className={`${styles.noLabelSelect} ${styles.measurementUnit}`}
								containerClassName={formStyles.noBottomMargin}
								value={value.weightUnit}
								options={weightUnits}
								onChange={weightUnit => onChange({ ...value, weightUnit })}
							/>
						</div>
					</div>
					{!props.singleRow ? (
						<span onClick={onRemove} className={`clickable btn-link ${styles.removeBtn}`}>
							<IconClose />
						</span>
					) : null}
				</div>
				{hasDimensionsError(errors) ? (
					<small className="help-block">Define dimensions and weight of the package</small>
				) : null}
				<Checkbox
					className={styles.packageValueCheckbox}
					label="Also has specific value"
					checked={value.hasShipmentValue}
					payload="disable"
					onChange={() =>
						onChange({
							shipmentValue: { scalar: '', currency: defaultCurrency }, // initializes the value for the first time
							...value,
							hasShipmentValue: !value.hasShipmentValue,
						})
					}
				/>
			</div>
			{value.hasShipmentValue ? (
				<ValueInput
					value={value.shipmentValue}
					onChange={shipmentValue => onChange({ ...value, shipmentValue })}
					errors={errors && errors.shipmentValue}
				/>
			) : null}
		</>
	)
}
