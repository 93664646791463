import { useCallback, useState } from 'react'
import axios from 'axios'

const PLANNING_API_SWAGGER_URL = 'https://planning.logistics.cimpress.io/swagger'

const useSwaggerSchema = () => {
	const [error, setError] = useState(undefined)
	const [isLoading, setIsLoading] = useState(false)
	const [schema, setSchema] = useState({})
	const load = useCallback(async () => {
		setIsLoading(true)
		setError(undefined)
		try {
			const { data: schema } = await axios.get(PLANNING_API_SWAGGER_URL)
			setSchema(schema)
		} catch (error) {
			setError(error)
		} finally {
			setIsLoading(false)
		}
	}, [])
	return { isLoading, error, load, schema }
}

export default useSwaggerSchema
