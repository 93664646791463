import axios from 'axios'

const loadSupportedCurrencies = async token => {
	const response = await axios.get(`${process.env.REACT_APP_EXCHANGE_RATES_SERVICE_URL}/v1/supportedCurrencies`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
	return response.data
}

export default loadSupportedCurrencies
