export default [
	{ value: 'AF', countryName: 'Afghanistan (AF)' },
	{ value: 'AX', countryName: 'Åland Islands (AX)' },
	{ value: 'AL', countryName: 'Albania (AL)' },
	{ value: 'DZ', countryName: 'Algeria (DZ)' },
	{ value: 'AS', countryName: 'American Samoa (AS)' },
	{ value: 'AD', countryName: 'Andorra (AD)' },
	{ value: 'AO', countryName: 'Angola (AO)' },
	{ value: 'AI', countryName: 'Anguilla (AI)' },
	{ value: 'AQ', countryName: 'Antarctica (AQ)' },
	{ value: 'AG', countryName: 'Antigua And Barbuda (AG)' },
	{ value: 'AR', countryName: 'Argentina (AR)' },
	{ value: 'AM', countryName: 'Armenia (AM)' },
	{ value: 'AW', countryName: 'Aruba (AW)' },
	{ value: 'AU', countryName: 'Australia (AU)' },
	{ value: 'AT', countryName: 'Austria (AT)' },
	{ value: 'AZ', countryName: 'Azerbaijan (AZ)' },
	{ value: 'BS', countryName: 'Bahamas (BS)' },
	{ value: 'BH', countryName: 'Bahrain (BH)' },
	{ value: 'BD', countryName: 'Bangladesh (BD)' },
	{ value: 'BB', countryName: 'Barbados (BB)' },
	{ value: 'BY', countryName: 'Belarus (BY)' },
	{ value: 'BE', countryName: 'Belgium (BE)' },
	{ value: 'BZ', countryName: 'Belize (BZ)' },
	{ value: 'BJ', countryName: 'Benin (BJ)' },
	{ value: 'BM', countryName: 'Bermuda (BM)' },
	{ value: 'BT', countryName: 'Bhutan (BT)' },
	{ value: 'BO', countryName: 'Bolivia, Plurinational State Of (BO)' },
	{ value: 'BQ', countryName: 'Bonaire, Saint Eustatius And Saba (BQ)' },
	{ value: 'BA', countryName: 'Bosnia &amp; Herzegovina (BA)' },
	{ value: 'BW', countryName: 'Botswana (BW)' },
	{ value: 'BV', countryName: 'Bouvet Island (BV)' },
	{ value: 'BR', countryName: 'Brazil (BR)' },
	{ value: 'IO', countryName: 'British Indian Ocean Territory (IO)' },
	{ value: 'BN', countryName: 'Brunei Darussalam (BN)' },
	{ value: 'BG', countryName: 'Bulgaria (BG)' },
	{ value: 'BF', countryName: 'Burkina Faso (BF)' },
	{ value: 'BI', countryName: 'Burundi (BI)' },
	{ value: 'CV', countryName: 'Cabo Verde (CV)' },
	{ value: 'KH', countryName: 'Cambodia (KH)' },
	{ value: 'CM', countryName: 'Cameroon (CM)' },
	{ value: 'CA', countryName: 'Canada (CA)' },
	{ value: 'KY', countryName: 'Cayman Islands (KY)' },
	{ value: 'CF', countryName: 'Central African Republic (CF)' },
	{ value: 'TD', countryName: 'Chad (TD)' },
	{ value: 'CL', countryName: 'Chile (CL)' },
	{ value: 'CN', countryName: 'China (CN)' },
	{ value: 'CX', countryName: 'Christmas Island (CX)' },
	{ value: 'CC', countryName: 'Cocos (Keeling) Islands (CC)' },
	{ value: 'CO', countryName: 'Colombia (CO)' },
	{ value: 'KM', countryName: 'Comoros (KM)' },
	{ value: 'CK', countryName: 'Cook Islands (CK)' },
	{ value: 'CR', countryName: 'Costa Rica (CR)' },
	{ value: 'CI', countryName: "Côte d'Ivoire (CI)" },
	{ value: 'HR', countryName: 'Croatia (HR)' },
	{ value: 'CU', countryName: 'Cuba (CU)' },
	{ value: 'CW', countryName: 'Curacao (CW)' },
	{ value: 'CY', countryName: 'Cyprus (CY)' },
	{ value: 'CZ', countryName: 'Czech Republic (CZ)' },
	{ value: 'CD', countryName: 'Democratic Republic Of Congo (CD)' },
	{ value: 'DK', countryName: 'Denmark (DK)' },
	{ value: 'DJ', countryName: 'Djibouti (DJ)' },
	{ value: 'DM', countryName: 'Dominica (DM)' },
	{ value: 'DO', countryName: 'Dominican Republic (DO)' },
	{ value: 'EC', countryName: 'Ecuador (EC)' },
	{ value: 'EG', countryName: 'Egypt (EG)' },
	{ value: 'SV', countryName: 'El Salvador (SV)' },
	{ value: 'GQ', countryName: 'Equatorial Guinea (GQ)' },
	{ value: 'ER', countryName: 'Eritrea (ER)' },
	{ value: 'EE', countryName: 'Estonia (EE)' },
	{ value: 'ET', countryName: 'Ethiopia (ET)' },
	{ value: 'FK', countryName: 'Falkland Islands (FK)' },
	{ value: 'FO', countryName: 'Faroe Islands (FO)' },
	{ value: 'FJ', countryName: 'Fiji (FJ)' },
	{ value: 'FI', countryName: 'Finland (FI)' },
	{ value: 'FR', countryName: 'France (FR)' },
	{ value: 'GF', countryName: 'French Guiana (GF)' },
	{ value: 'PF', countryName: 'French Polynesia (PF)' },
	{ value: 'TF', countryName: 'French Southern Territories (TF)' },
	{ value: 'GA', countryName: 'Gabon (GA)' },
	{ value: 'GM', countryName: 'Gambia (GM)' },
	{ value: 'GE', countryName: 'Georgia (GE)' },
	{ value: 'DE', countryName: 'Germany (DE)' },
	{ value: 'GH', countryName: 'Ghana (GH)' },
	{ value: 'GI', countryName: 'Gibraltar (GI)' },
	{ value: 'GR', countryName: 'Greece (GR)' },
	{ value: 'GL', countryName: 'Greenland (GL)' },
	{ value: 'GD', countryName: 'Grenada (GD)' },
	{ value: 'GP', countryName: 'Guadeloupe (GP)' },
	{ value: 'GU', countryName: 'Guam (GU)' },
	{ value: 'GT', countryName: 'Guatemala (GT)' },
	{ value: 'GG', countryName: 'Guernsey (GG)' },
	{ value: 'GN', countryName: 'Guinea (GN)' },
	{ value: 'GW', countryName: 'Guinea-bissau (GW)' },
	{ value: 'GY', countryName: 'Guyana (GY)' },
	{ value: 'HT', countryName: 'Haiti (HT)' },
	{ value: 'HM', countryName: 'Heard Island And McDonald Islands (HM)' },
	{ value: 'HN', countryName: 'Honduras (HN)' },
	{ value: 'HK', countryName: 'Hong Kong (HK)' },
	{ value: 'HU', countryName: 'Hungary (HU)' },
	{ value: 'IS', countryName: 'Iceland (IS)' },
	{ value: 'IN', countryName: 'India (IN)' },
	{ value: 'ID', countryName: 'Indonesia (ID)' },
	{ value: 'IR', countryName: 'Iran, Islamic Republic Of (IR)' },
	{ value: 'IQ', countryName: 'Iraq (IQ)' },
	{ value: 'IE', countryName: 'Ireland (IE)' },
	{ value: 'IM', countryName: 'Isle Of Man (IM)' },
	{ value: 'IL', countryName: 'Israel (IL)' },
	{ value: 'IT', countryName: 'Italy (IT)' },
	{ value: 'JM', countryName: 'Jamaica (JM)' },
	{ value: 'JP', countryName: 'Japan (JP)' },
	{ value: 'JE', countryName: 'Jersey (JE)' },
	{ value: 'JO', countryName: 'Jordan (JO)' },
	{ value: 'KZ', countryName: 'Kazakhstan (KZ)' },
	{ value: 'KE', countryName: 'Kenya (KE)' },
	{ value: 'KI', countryName: 'Kiribati (KI)' },
	{ value: 'KP', countryName: "Korea, Democratic People's Republic Of (KP)" },
	{ value: 'KR', countryName: 'Korea, Republic Of (KR)' },
	{ value: 'KW', countryName: 'Kuwait (KW)' },
	{ value: 'KG', countryName: 'Kyrgyzstan (KG)' },
	{ value: 'LA', countryName: "Lao People's Democratic Republic (LA)" },
	{ value: 'LV', countryName: 'Latvia (LV)' },
	{ value: 'LB', countryName: 'Lebanon (LB)' },
	{ value: 'LS', countryName: 'Lesotho (LS)' },
	{ value: 'LR', countryName: 'Liberia (LR)' },
	{ value: 'LY', countryName: 'Libya (LY)' },
	{ value: 'LI', countryName: 'Liechtenstein (LI)' },
	{ value: 'LT', countryName: 'Lithuania (LT)' },
	{ value: 'LU', countryName: 'Luxembourg (LU)' },
	{ value: 'MO', countryName: 'Macao (MO)' },
	{
		value: 'MK',
		countryName: 'Macedonia, The Former Yugoslav Republic Of (MK)',
	},
	{ value: 'MG', countryName: 'Madagascar (MG)' },
	{ value: 'MW', countryName: 'Malawi (MW)' },
	{ value: 'MY', countryName: 'Malaysia (MY)' },
	{ value: 'MV', countryName: 'Maldives (MV)' },
	{ value: 'ML', countryName: 'Mali (ML)' },
	{ value: 'MT', countryName: 'Malta (MT)' },
	{ value: 'MH', countryName: 'Marshall Islands (MH)' },
	{ value: 'MQ', countryName: 'Martinique (MQ)' },
	{ value: 'MR', countryName: 'Mauritania (MR)' },
	{ value: 'MU', countryName: 'Mauritius (MU)' },
	{ value: 'YT', countryName: 'Mayotte (YT)' },
	{ value: 'MX', countryName: 'Mexico (MX)' },
	{ value: 'FM', countryName: 'Micronesia, Federated States Of (FM)' },
	{ value: 'MD', countryName: 'Moldova (MD)' },
	{ value: 'MC', countryName: 'Monaco (MC)' },
	{ value: 'MN', countryName: 'Mongolia (MN)' },
	{ value: 'ME', countryName: 'Montenegro (ME)' },
	{ value: 'MS', countryName: 'Montserrat (MS)' },
	{ value: 'MA', countryName: 'Morocco (MA)' },
	{ value: 'MZ', countryName: 'Mozambique (MZ)' },
	{ value: 'MM', countryName: 'Myanmar (MM)' },
	{ value: 'NA', countryName: 'Namibia (NA)' },
	{ value: 'NR', countryName: 'Nauru (NR)' },
	{ value: 'NP', countryName: 'Nepal (NP)' },
	{ value: 'NL', countryName: 'Netherlands (NL)' },
	{ value: 'NC', countryName: 'New Caledonia (NC)' },
	{ value: 'NZ', countryName: 'New Zealand (NZ)' },
	{ value: 'NI', countryName: 'Nicaragua (NI)' },
	{ value: 'NE', countryName: 'Niger (NE)' },
	{ value: 'NG', countryName: 'Nigeria (NG)' },
	{ value: 'NU', countryName: 'Niue (NU)' },
	{ value: 'NF', countryName: 'Norfolk Island (NF)' },
	{ value: 'MP', countryName: 'Northern Mariana Islands (MP)' },
	{ value: 'NO', countryName: 'Norway (NO)' },
	{ value: 'OM', countryName: 'Oman (OM)' },
	{ value: 'PK', countryName: 'Pakistan (PK)' },
	{ value: 'PW', countryName: 'Palau (PW)' },
	{ value: 'PS', countryName: 'Palestinian Territory, Occupied (PS)' },
	{ value: 'PA', countryName: 'Panama (PA)' },
	{ value: 'PG', countryName: 'Papua New Guinea (PG)' },
	{ value: 'PY', countryName: 'Paraguay (PY)' },
	{ value: 'PE', countryName: 'Peru (PE)' },
	{ value: 'PH', countryName: 'Philippines (PH)' },
	{ value: 'PN', countryName: 'Pitcairn (PN)' },
	{ value: 'PL', countryName: 'Poland (PL)' },
	{ value: 'PT', countryName: 'Portugal (PT)' },
	{ value: 'PR', countryName: 'Puerto Rico (PR)' },
	{ value: 'QA', countryName: 'Qatar (QA)' },
	{ value: 'CG', countryName: 'Republic Of Congo (CG)' },
	{ value: 'RE', countryName: 'Reunion (RE)' },
	{ value: 'RO', countryName: 'Romania (RO)' },
	{ value: 'RU', countryName: 'Russian Federation (RU)' },
	{ value: 'RW', countryName: 'Rwanda (RW)' },
	{ value: 'BL', countryName: 'Saint Barthélemy (BL)' },
	{
		value: 'SH',
		countryName: 'Saint Helena, Ascension And Tristan Da Cunha (SH)',
	},
	{ value: 'KN', countryName: 'Saint Kitts And Nevis (KN)' },
	{ value: 'LC', countryName: 'Saint Lucia (LC)' },
	{ value: 'MF', countryName: 'Saint Martin (MF)' },
	{ value: 'PM', countryName: 'Saint Pierre And Miquelon (PM)' },
	{ value: 'VC', countryName: 'Saint Vincent And The Grenadines (VC)' },
	{ value: 'WS', countryName: 'Samoa (WS)' },
	{ value: 'SM', countryName: 'San Marino (SM)' },
	{ value: 'ST', countryName: 'Sao Tome and Principe (ST)' },
	{ value: 'SA', countryName: 'Saudi Arabia (SA)' },
	{ value: 'SN', countryName: 'Senegal (SN)' },
	{ value: 'RS', countryName: 'Serbia (RS)' },
	{ value: 'SC', countryName: 'Seychelles (SC)' },
	{ value: 'SL', countryName: 'Sierra Leone (SL)' },
	{ value: 'SG', countryName: 'Singapore (SG)' },
	{ value: 'SX', countryName: 'Sint Maarten (SX)' },
	{ value: 'SK', countryName: 'Slovakia (SK)' },
	{ value: 'SI', countryName: 'Slovenia (SI)' },
	{ value: 'SB', countryName: 'Solomon Islands (SB)' },
	{ value: 'SO', countryName: 'Somalia (SO)' },
	{ value: 'ZA', countryName: 'South Africa (ZA)' },
	{
		value: 'GS',
		countryName: 'South Georgia And The South Sandwich Islands (GS)',
	},
	{ value: 'SS', countryName: 'South Sudan (SS)' },
	{ value: 'ES', countryName: 'Spain (ES)' },
	{ value: 'LK', countryName: 'Sri Lanka (LK)' },
	{ value: 'SD', countryName: 'Sudan (SD)' },
	{ value: 'SR', countryName: 'Suriname (SR)' },
	{ value: 'SJ', countryName: 'Svalbard And Jan Mayen (SJ)' },
	{ value: 'SZ', countryName: 'Swaziland (SZ)' },
	{ value: 'SE', countryName: 'Sweden (SE)' },
	{ value: 'CH', countryName: 'Switzerland (CH)' },
	{ value: 'SY', countryName: 'Syrian Arab Republic (SY)' },
	{ value: 'TW', countryName: 'Taiwan (TW)' },
	{ value: 'TJ', countryName: 'Tajikistan (TJ)' },
	{ value: 'TZ', countryName: 'Tanzania, United Republic Of (TZ)' },
	{ value: 'TH', countryName: 'Thailand (TH)' },
	{ value: 'TL', countryName: 'Timor-Leste, Democratic Republic of (TL)' },
	{ value: 'TG', countryName: 'Togo (TG)' },
	{ value: 'TK', countryName: 'Tokelau (TK)' },
	{ value: 'TO', countryName: 'Tonga (TO)' },
	{ value: 'TT', countryName: 'Trinidad And Tobago (TT)' },
	{ value: 'TN', countryName: 'Tunisia (TN)' },
	{ value: 'TR', countryName: 'Turkey (TR)' },
	{ value: 'TM', countryName: 'Turkmenistan (TM)' },
	{ value: 'TC', countryName: 'Turks And Caicos Islands (TC)' },
	{ value: 'TV', countryName: 'Tuvalu (TV)' },
	{ value: 'UG', countryName: 'Uganda (UG)' },
	{ value: 'UA', countryName: 'Ukraine (UA)' },
	{ value: 'AE', countryName: 'United Arab Emirates (AE)' },
	{ value: 'GB', countryName: 'United Kingdom (GB)' },
	{ value: 'US', countryName: 'United States (US)' },
	{ value: 'UM', countryName: 'United States Minor Outlying Islands (UM)' },
	{ value: 'UY', countryName: 'Uruguay (UY)' },
	{ value: 'UZ', countryName: 'Uzbekistan (UZ)' },
	{ value: 'VU', countryName: 'Vanuatu (VU)' },
	{ value: 'VA', countryName: 'Vatican City State (VA)' },
	{ value: 'VE', countryName: 'Venezuela, Bolivarian Republic Of (VE)' },
	{ value: 'VN', countryName: 'Viet Nam (VN)' },
	{ value: 'VG', countryName: 'Virgin Islands (British) (VG)' },
	{ value: 'VI', countryName: 'Virgin Islands (US) (VI)' },
	{ value: 'WF', countryName: 'Wallis And Futuna (WF)' },
	{ value: 'EH', countryName: 'Western Sahara (EH)' },
	{ value: 'YE', countryName: 'Yemen (YE)' },
	{ value: 'ZM', countryName: 'Zambia (ZM)' },
	{ value: 'ZW', countryName: 'Zimbabwe (ZW)' },
]
