import React, { useContext } from 'react'
import AVAILABLE_CAPABILITIES from './availableCapabilities'
import OnDemandServices from './OnDemandServices'
import CashOnDeliveryParams from './CashOnDeliveryParams'
import ScheduledDeliveryParams from './ScheduledDeliveryParams'
import CarrierServicesContext from '../../../context/CarrierServicesContext'
import SelectMultipleWithPlaceholder from '../../SelectValue/SelectMultipleWithPlaceholder'
import _ from 'lodash'
import formStyles from '../RequestForm.module.css'

export default function RequirementsInput({
	include = [],
	require = [],
	prefer = [],
	requireErrors,
	onChangePrefer,
	onChangeRequire,
	onChangeInclude,
}) {
	const { carrierServices: availableCarrierServices } = useContext(CarrierServicesContext)

	const isContainingParametricCapability = Object.values(AVAILABLE_CAPABILITIES)
		.filter(({ isParametric }) => isParametric)
		.map(({ value }) => value)
		.find(value => require.capabilities.includes(value))

	const isCapabilitySelected = capability => require.capabilities.find(value => value === capability.value)

	const availableCapabilitiesArr = Object.values(AVAILABLE_CAPABILITIES)
	const customCapabilities = require.capabilities
		.filter(
			capability =>
				!availableCapabilitiesArr.map(availableCapability => availableCapability.value).includes(capability),
		)
		.map(customCapability => ({
			label: `user:${customCapability}`,
			value: customCapability,
		}))
	const createCapabilityLabel = input => `User capability: "user:${input}"`
	return (
		<>
			<h5 className={formStyles.header}>Carrier Restrictions</h5>
			<SelectMultipleWithPlaceholder
				containerClassName={formStyles.noBottomMargin}
				label="Required Carrier services"
				placeholder={'All active services'}
				values={require.carrierServices}
				onChange={carrierServices => onChangeRequire({ ...require, carrierServices })}
				options={availableCarrierServices}
				isClearable
				tether
			/>
			{prefer && onChangePrefer ? (
				<>
					<h5>Carrier Preference</h5>
					<SelectMultipleWithPlaceholder
						containerClassName={formStyles.noBottomMargin}
						label="Preferred Carrier services"
						placeholder={'No preference'}
						values={prefer.carrierServices}
						onChange={carrierServices => onChangePrefer({ ...prefer, carrierServices })}
						options={availableCarrierServices}
						isClearable
						tether
					/>
				</>
			) : null}

			<OnDemandServices value={include} onChange={onChangeInclude} />
			<SelectMultipleWithPlaceholder
				className={formStyles.row}
				label="Required Capabilities"
				placeholder={'Any capabilities'}
				values={require.capabilities}
				onChange={capabilities => {
					onChangeRequire({ ...require, capabilities })
				}}
				options={[...availableCapabilitiesArr, ...customCapabilities]}
				isClearable
				tether
				isCreatable
				createLabel={createCapabilityLabel}
			/>
			{isContainingParametricCapability ? (
				<>
					<h5 className={formStyles.header}>Because you selected configurable capability, we need more information:</h5>
					{isCapabilitySelected(AVAILABLE_CAPABILITIES.scheduledDelivery) ? (
						<ScheduledDeliveryParams
							value={require.capabilityParams[AVAILABLE_CAPABILITIES.scheduledDelivery.value]}
							onChange={capabilityValue =>
								onChangeRequire({
									...require,
									capabilityParams: {
										...require.capabilityParams,
										[AVAILABLE_CAPABILITIES.scheduledDelivery.value]: capabilityValue,
									},
								})
							}
							errors={_.get(requireErrors, `capabilityParams.${AVAILABLE_CAPABILITIES.scheduledDelivery.value}`)}
							required
						/>
					) : undefined}
					{isCapabilitySelected(AVAILABLE_CAPABILITIES.cashOnDelivery) ? (
						<CashOnDeliveryParams
							value={require.capabilityParams[AVAILABLE_CAPABILITIES.cashOnDelivery.value]}
							onChange={capabilityValue =>
								onChangeRequire({
									...require,
									capabilityParams: {
										...require.capabilityParams,
										[AVAILABLE_CAPABILITIES.cashOnDelivery.value]: capabilityValue,
									},
								})
							}
							errors={_.get(requireErrors, `capabilityParams.${AVAILABLE_CAPABILITIES.cashOnDelivery.value}`)}
							required
						/>
					) : undefined}
				</>
			) : undefined}
		</>
	)
}
