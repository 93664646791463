import React, { useEffect } from 'react'
import { shapes } from '@cimpress/react-components'

import RequestForm from '../RequestForm/RequestForm'
import ResultPane from '../ResultPane/ResultPane'
import DeliverableDatesResult from '../DeliverableDatesResult/DeliverableDatesResult'
import DeliverableDatesForm from '../RequestForm/DeliverableDatesForm/DeliverableDatesForm'
import useDeliverableDates from '../../hooks/useDeliverableDates'

const { Robot } = shapes

export const DeliveryDatesApp = ({ onError = () => {} }) => {
	const { data, error, clear, isLoading, load } = useDeliverableDates()

	useEffect(() => () => clear(), [clear])
	useEffect(() => {
		error && onError(error)
	}, [error, onError])

	return (
		<>
			<RequestForm submitLabel="Show me the delivery dates" FormComponent={DeliverableDatesForm} onSubmit={load} />
			<ResultPane
				ResultComponent={DeliverableDatesResult}
				AppIcon={<Robot bsStyle="success" />}
				resultName="possible delivery dates"
				isLoading={isLoading}
				result={data}
			/>
		</>
	)
}
