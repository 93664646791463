import { useContext, useState, useCallback } from 'react'
import AuthContext from '../context/AuthContext'
import { fetchLinkedResources } from '../services/recommendations'
import { convertRecommendationsResponseToRouteDiagnostics } from '../services/configurationDiagnostics'
import { loadRecommendations } from './useRecommendations'

const useConfigurationDiagnostics = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [data, setData] = useState()
	const { token } = useContext(AuthContext)
	const load = useCallback(
		async request => {
			try {
				setError(undefined)
				setData(undefined)
				setIsLoading(true)
				const recommendationsResponse = await loadRecommendations(request, token)
				await Promise.all([
					fetchLinkedResources(recommendationsResponse.recommendations, token),
					fetchLinkedResources(recommendationsResponse.invalidPlans, token),
				])

				setData(convertRecommendationsResponseToRouteDiagnostics(recommendationsResponse, token))
			} catch (error) {
				setError(error)
			} finally {
				setIsLoading(false)
			}
		},
		[token],
	)
	const clear = useCallback(() => {
		setData(undefined)
		setError(undefined)
	}, [])
	return {
		data,
		clear,
		isLoading,
		error,
		load,
	}
}

export default useConfigurationDiagnostics
