import { useContext, useState, useCallback } from 'react'
import loadCarrierServices from '../services/carriers'
import AuthContext from '../context/AuthContext'

const useCarrierServices = () => {
	const [carrierServices, setCarrierServices] = useState(undefined)
	const [error, setError] = useState(undefined)
	const [isLoading, setIsLoading] = useState(false)

	const { token } = useContext(AuthContext)

	const load = useCallback(async () => {
		setIsLoading(true)
		setError(undefined)
		setCarrierServices(undefined)
		try {
			const carrierServices = await loadCarrierServices(token)
			setCarrierServices(
				carrierServices.map(({ key: value, name: label }) => ({
					value,
					label,
				})),
			)
		} catch (error) {
			setError(error)
		} finally {
			setIsLoading(false)
		}
	}, [token])
	return { carrierServices, load, error, isLoading }
}

export default useCarrierServices
