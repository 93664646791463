import React, { useContext } from 'react'
import { Checkbox } from '@cimpress/react-components'
import OnDemandServicesContext from '../../../context/OnDemandServicesContext'
import styles from './RequirementsInput.module.css'
import formStyles from '../RequestForm.module.css'
import SelectMultipleWithPlaceholder from '../../SelectValue/SelectMultipleWithPlaceholder'

const OnDemandServices = ({ value = {}, onChange }) => {
	const { onDemandServices: availableOnDemandServices } = useContext(OnDemandServicesContext)
	return (
		<div className={styles.onDemandRow}>
			<Checkbox
				className={formStyles.checkbox}
				checked={value.isActive}
				onChange={() => onChange({ ...value, isActive: !value.isActive })}
				label="Include specific on-demand services"
			/>
			{value.isActive ? (
				<SelectMultipleWithPlaceholder
					label={'On-demand services'}
					values={value.carrierServices}
					onChange={carrierServices => onChange({ ...value, carrierServices })}
					placeholder="No on-demand services"
					options={availableOnDemandServices}
					tether
				/>
			) : undefined}
		</div>
	)
}

export default OnDemandServices
