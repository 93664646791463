import FulfillmentLocation from '../FulfillmentLocation'
import ShippingDestination from '../DestinationInput/DestinationInput'
import ShipmentInput from '../ShipmentInput/ShipmentInput'
import DateInput from '../DateInput/DateInput'
import RequirementsInput from '../RequirementsInput/RequirementsInput'

import SortingAndLimit from '../SortingAndLimit/SortingAndLimit'
import React, { useContext, useEffect, useMemo } from 'react'
import ShowMoreInput from '../ShowMoreInput/ShowMoreInput'
import FormContext from '../../../context/FormContext'
import { convertRecommendationsFormFieldsToRequest } from '../utils/convertFormFieldsToRequest'
import StrategyInput from '../StrategyInput/StrategyInput'

import RequestInputTypeSwitcher, { REQUEST_INPUT_TYPE } from '../RequestInputTypeSwitcher/RequestInputTypeSwitcher'
import RawRequest from '../RawRequest/RawRequest'
import { SwaggerSchemaContext } from '../../../context/SwaggerSchemaContext'

const HARDCODED_FIELDS = {
	options: {
		includeCostBreakDown: true,
	},
}

export default function ShipRecForm({ onChange, onChangeViewType, showError }) {
	const { fields, updateFields } = useContext(FormContext)
	const { validate: swaggerValidate } = useContext(SwaggerSchemaContext)
	const { request, hasError, errors } = useMemo(() => {
		return convertRecommendationsFormFieldsToRequest(fields, HARDCODED_FIELDS, swaggerValidate)
	}, [fields, swaggerValidate])
	const { autoSubmit } = fields

	useEffect(() => {
		onChange(request, hasError, autoSubmit)
	}, [request, hasError, autoSubmit, onChange])

	function getForm() {
		return (
			<>
				<FulfillmentLocation
					value={fields.fulfillmentLocation}
					onChange={updateFields('fulfillmentLocation')}
					errors={showError && errors.fulfillmentLocation}
					required
				/>
				<ShippingDestination
					value={fields.shippingDestination}
					onChange={updateFields('shippingDestination')}
					errors={showError && errors.shippingDestination}
					required
				/>
				<hr />
				<ShipmentInput
					value={fields.shipment}
					onChange={updateFields('shipment')}
					errors={showError && errors.shipment}
					required
				/>
				<hr />
				<DateInput
					label="Shipment Creation Date"
					value={fields.earliestShipDateTime}
					onChange={updateFields('earliestShipDateTime')}
					errors={showError && errors.earliestShipDateTime}
					includeTime
					required
				/>
				<DateInput
					label="Customer Promised Date"
					value={fields.expectedDeliveryDate}
					onChange={updateFields('expectedDeliveryDate')}
					errors={showError && errors.expectedDeliveryDate}
					required
				/>
				<hr />
				<ShowMoreInput
					labelShow="Show advanced options"
					labelHide="Hide advanced options"
					isOpened={fields.isShowMoreOpened}
					onChange={updateFields('isShowMoreOpened')}
				>
					<RequirementsInput
						require={fields.require}
						prefer={fields.prefer}
						include={fields.include}
						onChangeInclude={updateFields('include')}
						onChangeRequire={updateFields('require')}
						onChangePrefer={updateFields('prefer')}
						requireErrors={showError && errors.require}
					/>
					<hr />
					<StrategyInput value={fields.priorities} onChange={updateFields('priorities')} />
					<SortingAndLimit
						value={fields.sortAndLimit}
						onChange={updateFields('sortAndLimit')}
						errors={showError && errors.sortAndLimit}
					/>
				</ShowMoreInput>
			</>
		)
	}

	function getRawRequest() {
		return (
			<RawRequest
				errors={showError && errors.rawRequestRecommendations}
				value={fields.rawRequestRecommendations}
				onChange={updateFields('rawRequestRecommendations')}
				hardcodedFields={HARDCODED_FIELDS}
			/>
		)
	}
	return (
		<>
			<RequestInputTypeSwitcher
				value={fields.requestInputType}
				onChange={value => {
					onChangeViewType()
					updateFields('requestInputType')(value)
				}}
			/>
			{fields.requestInputType === REQUEST_INPUT_TYPE.FORM ? getForm() : getRawRequest()}
		</>
	)
}
