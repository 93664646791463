import React from 'react'
import { APP_TYPE } from '../../MainPage'
import { Link, useLocation, generatePath, matchPath } from 'react-router-dom'

export default function AppTypeSwitcher() {
	const { pathname } = useLocation()
	return (
		<div className={`tab-card`}>
			<div className="card-header card-tabs">
				<ul className="nav card-tab-nav">
					{Object.values(APP_TYPE).map(({ path, name }, index) => (
						<li key={index} className={`nav-item ${matchPath(pathname, { path, exact: true }) ? 'active' : ''}`}>
							<Link to={generatePath(path)} replace={true}>
								<strong>{name}</strong>
							</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
