import React, { createContext } from 'react'

const onDemandServices = [
	{ value: 'first', label: 'On demand service example' },
	{ value: 'second', label: 'On demand service 2' },
]

export const OnDemandServicesContext = createContext({
	onDemandServices,
})

export const OnDemandServicesProvider = ({ children, value }) => {
	const context = {
		onDemandServices: value || onDemandServices,
	}

	return <OnDemandServicesContext.Provider value={context}>{children}</OnDemandServicesContext.Provider>
}

export default OnDemandServicesContext
