import React, { useEffect } from 'react'
import { shapes } from '@cimpress/react-components'

import RequestForm from '../RequestForm/RequestForm'
import ResultPane from '../ResultPane/ResultPane'
import ConfigurationDiagnosticsForm from '../RequestForm/ConfigurationDiagnosticsForm/ConfigurationDiagnosticsForm'
import ConfigurationDiagnosticsResult from '../ConfigurationDiagnosticsResult/ConfigurationDiagnosticsResult'
import useConfigurationDiagnostics from '../../hooks/useConfigurationDiagnostics'

const { Robot } = shapes

export const ConfigurationDiagnosticsApp = ({ onError = () => {} }) => {
	const { data, error, clear, isLoading, load } = useConfigurationDiagnostics()

	useEffect(() => () => clear(), [clear])
	useEffect(() => {
		error && onError(error)
	}, [error, onError])

	return (
		<>
			<RequestForm submitLabel="Show me the diagnostics" FormComponent={ConfigurationDiagnosticsForm} onSubmit={load} />
			<ResultPane
				ResultComponent={ConfigurationDiagnosticsResult}
				AppIcon={<Robot bsStyle="danger" />}
				resultName="configuration diagnostics"
				isLoading={isLoading}
				result={data}
			/>
		</>
	)
}
