import React, { useContext, useState } from 'react'
import LocaleContext from '../../context/LocaleContext'
import moment from 'moment-timezone'
import 'moment/min/locales'
import formStyles from '../RequestForm/RequestForm.module.css'
import styles from './ResultCard.module.css'
import { renderDisplayCurrency } from '../../services/currency'
import { Label, CodeBlock } from '@cimpress/react-components'
import IconHouse from '@cimpress-technology/react-streamline-icons/lib/IconHouse'
import IconFactory from '@cimpress-technology/react-streamline-icons/lib/IconFactory'
import IconQuestionCircle from '@cimpress-technology/react-streamline-icons/lib/IconQuestionCircle'
import { Step, StepGroup, Stepper, Tooltip } from '@cimpress/react-components'
import ShowMoreInput from '../RequestForm/ShowMoreInput/ShowMoreInput'
import Card from '@cimpress/react-components/lib/Card'

const RESULT_TYPE = {
	FORMATTED: 'formatted',
	RAW: 'raw',
}

export default function ResultCard({ plan }) {
	const { locale } = useContext(LocaleContext)
	const [isOpened, setIsOpened] = useState(false)
	const [resultType, setResultType] = useState(RESULT_TYPE.FORMATTED)

	function renderDisplayIsOnTime(isOnTime) {
		if (isOnTime) {
			return <Label type="success" text="Delivery on time" className={styles.label} />
		} else {
			return <Label type="warning" text="Delivery after promised date" className={styles.label} />
		}
	}

	function renderLeg(leg) {
		return `${leg.name} (${leg.carrierServiceKey})`
	}
	function renderRoute() {
		const renderedLegs = []
		for (const [i, leg] of plan.shipments[0].legs.entries()) {
			renderedLegs.push(<Step key={i}>{renderLeg(leg)}</Step>)
		}
		return (
			<>
				<h5 className="text-muted">Route</h5>
				<Stepper activeStep={2} vertical>
					<StepGroup overrideBsStyle={'none'} icon={<IconFactory weight="fill" />} contents={<h5>Fulfiller</h5>}>
						{renderedLegs}
					</StepGroup>
					<Step overrideBsStyle={'none'} icon={<IconHouse weight="fill" />}>
						<h5>Customer</h5>
					</Step>
				</Stepper>
			</>
		)
	}

	function renderShipments() {
		return (
			<>
				All packages will be delivered as {plan.shipments.length}{' '}
				{plan.shipments.length === 1 ? 'shipment' : 'shipments'}
			</>
		)
	}

	function renderBreakdownItem(breakdownItem) {
		return (
			<div key={breakdownItem.candidateShipmentId + breakdownItem.amount + breakdownItem.id}>
				<span className={styles.breakdownLabel}>{breakdownItem.name}</span>
				{': '}
				{renderDisplayCurrency(breakdownItem.amount, plan.costEstimate.unifiedCurrency.currencyCode, locale)}
			</div>
		)
	}
	function renderBreakdown() {
		const shippingCosts = plan.costEstimate.unifiedCurrency.breakdown.filter(item => item.isShippingCost)
		const additionalCosts = plan.costEstimate.unifiedCurrency.breakdown.filter(item => !item.isShippingCost)
		return (
			<>
				{shippingCosts.length ? (
					<>
						<h5>Shipping Costs</h5>
						{shippingCosts.map(renderBreakdownItem)}
					</>
				) : (
					''
				)}

				{additionalCosts.length ? (
					<>
						<h5>Additional Costs</h5>
						{additionalCosts.map(renderBreakdownItem)}
					</>
				) : (
					''
				)}
			</>
		)
	}

	function renderRawResult() {
		return <CodeBlock code={JSON.stringify(plan, null, 2)} />
	}

	function renderFormattedResult() {
		return (
			<>
				{renderRoute()}
				<hr />
				<ShowMoreInput labelShow="Show details" labelHide="Hide details" isOpened={isOpened} onChange={setIsOpened}>
					{renderShipments()}
					{renderBreakdown()}
				</ShowMoreInput>
			</>
		)
	}

	return (
		<div className={styles.resultBlock}>
			<Card className={styles.resultCard}>
				<div className={`${formStyles.row} ${styles.cardWidth}`}>
					<h4 className={styles.header}>
						Delivers on {moment.parseZone(plan.transitEstimate.deliveryDate).locale(locale).format('LL')}{' '}
						{renderDisplayIsOnTime(!plan.transitEstimate.lateArrival)}
						<span className={'pull-right'}>
							Shipping Cost
							<Tooltip contents={'The actual cost that the carrier will charge us for shipping'}>
								<IconQuestionCircle color="#0088a9" weight="fill" className={styles.helpIcon} />
							</Tooltip>
							:{' '}
							{renderDisplayCurrency(
								plan.costEstimate.unifiedCurrency.shipping,
								plan.costEstimate.unifiedCurrency.currencyCode,
								locale,
							)}
							{plan.costEstimate.carrierServiceCurrency && (
								<span>
									{' '}
									(
									{renderDisplayCurrency(
										plan.costEstimate.carrierServiceCurrency.shipping,
										plan.costEstimate.carrierServiceCurrency.currencyCode,
										locale,
									)}
									)
									<Tooltip contents={'In carrier service currency'}>
										<IconQuestionCircle color="#0088a9" weight="fill" className={styles.helpIcon} />
									</Tooltip>
								</span>
							)}
						</span>
					</h4>
					<span className={'pull-right'}>
						Total Cost
						<Tooltip
							contents={
								'Shipping cost + surcharges, that are artificially increasing/decreasing the cost of shipping. Fulfillers typically add extra non shipping surcharges to influence how services will be recommended. If not selected otherwise, this cost is used for sorting of these results, however you should use only shipping cost for your calculations. '
							}
						>
							<IconQuestionCircle color="#0088a9" weight="fill" className={styles.helpIcon} />
						</Tooltip>
						:{' '}
						{renderDisplayCurrency(
							plan.costEstimate.unifiedCurrency.total,
							plan.costEstimate.unifiedCurrency.currencyCode,
							locale,
						)}
						{plan.costEstimate.carrierServiceCurrency && (
							<span>
								{' '}
								(
								{renderDisplayCurrency(
									plan.costEstimate.carrierServiceCurrency.total,
									plan.costEstimate.carrierServiceCurrency.currencyCode,
									locale,
								)}
								)
								<Tooltip contents={'In carrier service currency'}>
									<IconQuestionCircle color="#0088a9" weight="fill" className={styles.helpIcon} />
								</Tooltip>
							</span>
						)}
					</span>
					Ship it on {moment.parseZone(plan.transitEstimate.shipDateTime).locale(locale).format('LL')} <hr />
					{resultType === RESULT_TYPE.FORMATTED ? renderFormattedResult() : renderRawResult()}
				</div>
			</Card>
			<div className={styles.tabs}>
				<ul>
					<li
						className={resultType === RESULT_TYPE.FORMATTED ? styles.activeTab : ''}
						onClick={() => setResultType(RESULT_TYPE.FORMATTED)}
					>
						Formatted
					</li>
					<li
						className={resultType === RESULT_TYPE.RAW ? styles.activeTab : ''}
						onClick={() => setResultType(RESULT_TYPE.RAW)}
					>
						Raw
					</li>
				</ul>
			</div>
		</div>
	)
}
