import React from 'react'
import { FlexBox, TextField } from '@cimpress/react-components'
import SelectValue from '../../SelectValue/SelectValue'
import styles from './SortingAndLimit.module.css'
import formStyles from '../RequestForm.module.css'
import { useAutoScroll } from '../../../hooks/useAutomaticScroll'

const costToSortOptions = [
	{ value: 'totalCost', label: 'Shipping Cost + Surcharges' },
	{ value: 'shippingCost', label: 'Shipping Cost' },
]

const SortingAndLimit = ({ value, errors, onChange }) => {
	const automaticScrolling = useAutoScroll()

	return (
		<>
			<h5 className={formStyles.header}>Estimates</h5>
			<FlexBox marginX="m" className={formStyles.bottomMargin}>
				<span className={`text-center ${formStyles.verticalCenter}`}>Show</span>
				<TextField
					type="number"
					min="1"
					className={`${formStyles.noBottomMargin} ${styles.recommendationsNumber}`}
					value={value.maxNumberOfRecommendations}
					onChange={result => {
						const numValue = parseInt(result.target.value)
						if (result.target.value !== '' && isNaN(numValue)) {
							return // we are not allowing anything other than integer
						}
						onChange({
							...value,
							maxNumberOfRecommendations: numValue.toString(),
						})
					}}
					bsStyle={errors && errors.maxNumberOfRecommendations ? 'error' : ''}
					helpText={errors && errors.maxNumberOfRecommendations ? 'Please enter positive number' : null}
				/>
				<span className={`text-center ${formStyles.verticalCenter}`} ref={automaticScrolling.elementRef}>
					estimates sorted by
				</span>
				<SelectValue
					containerClassName={`${formStyles.noBottomMargin} ${styles.recommendationsSort}`}
					label={'Cost to sort'}
					value={value.costToSort}
					onChange={costToSort => onChange({ ...value, costToSort })}
					options={costToSortOptions}
					onFocus={() => automaticScrolling.scrollToElement()}
					tether
				/>
			</FlexBox>
		</>
	)
}

export default SortingAndLimit
