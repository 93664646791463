import { createContext } from 'react'
import { getUserLocale } from 'get-user-locale'

function getLocale() {
	let locale = getUserLocale().toLowerCase()
	if (locale !== 'en' && locale !== 'en-us') {
		locale = 'en-gb'
	}
	return locale
}

const getLocaleCountry = () => {
	const [, countryCode = 'US'] = getUserLocale().toUpperCase().split('-')
	return countryCode
}

const getLocaleCurrency = () => {
	// TODO: Come with better user currency logic
	const country = getLocaleCountry()
	switch (country) {
		case 'US':
			return 'USD'
		default:
			return 'EUR'
	}
}

export default createContext({
	locale: getLocale(),
	countryCode: getLocaleCountry(),
	currency: getLocaleCurrency(),
})
