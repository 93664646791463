import React from 'react'
import { shapes } from '@cimpress/react-components'
import styles from './GlobalError.module.css'
const { Robot } = shapes

const GlobalError = ({ children, error }) => {
	return error ? (
		<div className={`${styles.errorContainer} ${styles.centerVertically}`}>
			<span className={styles.centerIcon}>
				<Robot bsStyle="danger" alternate />
			</span>
			<p className={styles.errorText}>
				Unfortunately, there was an error while loading the application. You can try fixing the issue by refreshing
				page.
			</p>
			<p className={styles.errorText}>
				If error persists, please contact our support at{' '}
				<a href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>{process.env.REACT_APP_SUPPORT_MAIL}</a> and in the
				message include the following error{' '}
			</p>
			<pre className={styles.errorMessage}>
				{error.toString()}
				<br />
				{error.stack ? error.stack : null}
			</pre>
		</div>
	) : (
		children
	)
}

export default GlobalError
