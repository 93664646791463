import React, { createContext, useMemo } from 'react'
import Ajv from 'ajv'
import _ from 'lodash'

export const SwaggerSchemaContext = createContext({
	schema: null,
	validate: () => {},
})

export const SwaggerSchemaContextProvider = ({ children, schema }) => {
	const ajv = useMemo(() => {
		if (!_.isEmpty(schema)) {
			const ajv = new Ajv({
				allErrors: true,
				coerceTypes: true,
			})
			return ajv.addSchema(schema, 'swagger')
		} else {
			return () => {}
		}
	}, [schema])

	const context = {
		schema,
		validate: (path, data) => {
			const isValid = ajv.validate(path, data)
			return {
				isValid,
				errors: !isValid ? ajv.errorsText(ajv.errors, { dataVar: 'request' }) : null,
			}
		},
	}

	return <SwaggerSchemaContext.Provider value={context}>{children}</SwaggerSchemaContext.Provider>
}
