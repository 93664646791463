import axios from 'axios'
import _ from 'lodash'

function applyToEachBreakdownItem(plan, func) {
	_.get(plan, 'costEstimate.unifiedCurrency.breakdown', []).forEach(func)
	plan.shipments.forEach(shipment => {
		_.get(shipment, 'costEstimate.unifiedCurrency.breakdown', []).forEach(func)
		_.get(shipment, 'costEstimate.carrierServiceCurrency.breakdown', []).forEach(func)
	})
}

function applyToEachLeg(plan, func) {
	plan.shipments.forEach(shipment => shipment.legs.forEach(func))
}

function applyToEachExclusionReason(plan, func) {
	plan.shipments.forEach(shipment => (shipment.exclusionReasons ? shipment.exclusionReasons.forEach(func) : null))
}

export async function fetchLinkedResources(recommendations, token) {
	const uniqueUrls = new Set()

	for (const plan of recommendations) {
		applyToEachBreakdownItem(plan, item => uniqueUrls.add(item.costType))
		applyToEachLeg(plan, leg => uniqueUrls.add(leg.carrierServiceConfigHref))
		applyToEachExclusionReason(plan, exclusionReason => uniqueUrls.add(exclusionReason.exclusionReasonType))
	}
	const resolvedUrlPairs = await Promise.all(
		Array.from(uniqueUrls).map(async url => [url, await fetchResource(url, token)]),
	)
	const urlMap = resolvedUrlPairs.reduce((acc, curr) => {
		acc[curr[0]] = curr[1]
		return acc
	}, {})

	for (const plan of recommendations) {
		applyToEachBreakdownItem(plan, item => {
			_.assign(item, urlMap[item.costType])
		})
		applyToEachLeg(plan, leg => {
			_.assign(leg, urlMap[leg.carrierServiceConfigHref])
		})
		applyToEachExclusionReason(plan, exclusionReason => {
			_.assign(exclusionReason, urlMap[exclusionReason.exclusionReasonType])
		})
	}
	return recommendations
}

async function fetchResource(url, token) {
	const response = await axios.get(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
	return response.data
}
