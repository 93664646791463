// auth hook for managing login status
import { useState, useEffect } from 'react'
import auth from '../services/auth'

export default () => {
	const [isAuthenticating, setIsAuthenticating] = useState(false)
	const [authError, setAuthError] = useState()
	const [isLoggedIn, setIsLoggedIn] = useState(auth.isLoggedIn())

	auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname, forceLogin: true }))

	useEffect(() => {
		const authorize = async () => {
			setIsAuthenticating(true)
			try {
				await auth.ensureAuthentication({
					nextUri: window.location.pathname + window.location.search,
				})
			} catch (e) {
				setAuthError(e)
			} finally {
				setIsAuthenticating(false)
				setIsLoggedIn(auth.isLoggedIn())
			}
		}
		if (!isLoggedIn) {
			authorize()
		}
	}, [isLoggedIn])

	return { isAuthenticating, authError, isLoggedIn, auth }
}
