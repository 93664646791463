import React from 'react'
import { FlexBox } from '@cimpress/react-components'
import IconQuestionCircle from '@cimpress-technology/react-streamline-icons/lib/IconQuestionCircle'
import PackageList from './PackageInput/PackageList'

import styles from './ShipmentInput.module.css'
import formStyles from '../RequestForm.module.css'
import SelectValue from '../../SelectValue/SelectValue'

export const SHIPMENT_TYPES = {
	PARCEL: 'parcel',
	PALLET: 'pallet',
}

const containerTypeOptions = [
	{ value: SHIPMENT_TYPES.PARCEL, label: 'Packages' },
	{ value: SHIPMENT_TYPES.PALLET, label: 'Pallets' },
]

export default function ShipmentInput({ value, onChange, errors }) {
	const renderContainersList = () => {
		switch (value.shipmentType) {
			case SHIPMENT_TYPES.PALLET: // Intentional fall thru
			case SHIPMENT_TYPES.PARCEL:
				return (
					<PackageList
						className="row"
						type={value.shipmentType}
						value={value.shipmentItems}
						onChange={shipmentItems => onChange({ ...value, shipmentItems })}
						errors={errors && errors.shipmentItems}
					/>
				)
			default:
				console.error(`Unknown container type "${value.shipmentType}"`)
		}
	}

	return (
		<div className={formStyles.formPart}>
			<div className="row">
				<div className="col col-lg-12">
					<FlexBox spaceBetween={true}>
						<h5 className={formStyles.header}>Shipment Type</h5>
						<a
							className={styles.learnMore}
							href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/854885387/Shipment+types"
						>
							<IconQuestionCircle weight="fill" /> Learn more about Shipment Types
						</a>
					</FlexBox>
					<SelectValue
						value={value.shipmentType}
						onChange={shipmentType => {
							onChange({ ...value, shipmentType })
						}}
						options={containerTypeOptions}
						className={styles.noLabelSelect}
						tether
					/>
				</div>
			</div>
			<div className="row">
				<div className={`col col-xs-12 ${formStyles.header}`}>
					<span className={`${formStyles.required}`}>with these dimensions</span>{' '}
					<span className="text-muted">(default units can be changed in the settings)</span>
				</div>
			</div>
			{renderContainersList()}
		</div>
	)
}
