import { useContext, useCallback, useState } from 'react'
import AuthContext from '../context/AuthContext'
import loadFulfillersNames from '../services/fulfillersNames'

const useFulfillersNames = () => {
	const [fulfillersNames, setFulfillersNames] = useState(undefined)
	const [error, setError] = useState(undefined)
	const [isLoading, setIsLoading] = useState(false)

	const { token } = useContext(AuthContext)

	const load = useCallback(async () => {
		setIsLoading(true)
		setError(undefined)
		setFulfillersNames(undefined)
		try {
			const response = await loadFulfillersNames(token)

			setFulfillersNames(
				response.reduce((map, carrierService) => {
					map[carrierService.fulfillerId] = carrierService.name
					return map
				}, {}),
			)
		} catch (error) {
			setError(error)
		} finally {
			setIsLoading(false)
		}
	}, [token])
	return { fulfillersNames, load, error, isLoading }
}

export default useFulfillersNames
