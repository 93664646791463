import React, { useContext } from 'react'
import { TextField, FlexBox } from '@cimpress/react-components'
import SelectValue from '../../SelectValue/SelectValue'
import SupportedCurrenciesContext from '../../../context/CurrenciesListContext'

import styles from '../ShipmentInput/ShipmentInput.module.css'
import formStyles from '../RequestForm.module.css'
import { ERROR_TYPE_REQUIRED } from '../utils/formErrorHandling'

export function ValueInput({ errors, value = {}, onChange }) {
	const { supportedCurrencies } = useContext(SupportedCurrenciesContext)

	return (
		<div className={`${styles.packageValueBox} ${errors ? 'has-error' : ''}`}>
			<FlexBox middle between marginX="m">
				<TextField
					className={`${formStyles.noBottomMargin} ${styles.input}`}
					type="number"
					min="0"
					step=".01"
					value={value.scalar}
					bsStyle={errors && errors.scalar ? 'error' : ''}
					onChange={({ target }) => onChange({ ...value, scalar: target.value })}
					required
				/>
				<SelectValue
					className="selectBox"
					containerClassName={[
						styles.currencySelect,
						formStyles.noBottomMargin,
						styles.input,
						errors ? 'has-error' : '',
					].join(' ')}
					label={'Currency'}
					value={value.currency}
					onChange={currency => onChange({ ...value, currency })}
					options={supportedCurrencies}
					tether
					getOptionLabel={option => <div>{option.value}</div>}
					required
				/>
			</FlexBox>
			{errors && errors.scalar === ERROR_TYPE_REQUIRED ? (
				<small className="help-block">Please enter value and currency</small>
			) : null}
		</div>
	)
}
