import FulfillmentLocation from '../FulfillmentLocation'
import ShippingDestination from '../DestinationInput/DestinationInput'
import ShipmentInput from '../ShipmentInput/ShipmentInput'
import DateInput from '../DateInput/DateInput'
import RequirementsInput from '../RequirementsInput/RequirementsInput'
import React, { useContext, useEffect, useMemo } from 'react'
import ShowMoreInput from '../ShowMoreInput/ShowMoreInput'
import FormContext from '../../../context/FormContext'

import { convertDeliveryDatesFormFieldsToRequest } from '../utils/convertFormFieldsToRequest'
import RawRequest from '../RawRequest/RawRequest'
import RequestInputTypeSwitcher, { REQUEST_INPUT_TYPE } from '../RequestInputTypeSwitcher/RequestInputTypeSwitcher'
import { SwaggerSchemaContext } from '../../../context/SwaggerSchemaContext'

export const HARDCODED_FIELDS = {
	options: {
		includeCheapestOption: true,
		includeCostBreakDown: true,
		includeAvailableTimeSlots: true,
	},
}

export default function DeliverableDatesForm({ onChange, onChangeViewType, showError }) {
	const { fields, updateFields } = useContext(FormContext)
	const { validate: swaggerValidate } = useContext(SwaggerSchemaContext)
	const { request, hasError, errors } = useMemo(() => {
		return convertDeliveryDatesFormFieldsToRequest(fields, HARDCODED_FIELDS, swaggerValidate)
	}, [fields, swaggerValidate])
	const { autoSubmit } = fields

	useEffect(() => {
		onChange(request, hasError, autoSubmit)
	}, [request, hasError, autoSubmit, onChange])
	function getForm() {
		return (
			<>
				<FulfillmentLocation
					value={fields.fulfillmentLocation}
					onChange={updateFields('fulfillmentLocation')}
					errors={showError && errors.fulfillmentLocation}
					required
				/>
				<ShippingDestination
					value={fields.shippingDestination}
					onChange={updateFields('shippingDestination')}
					errors={showError && errors.shippingDestination}
					required
				/>
				<hr />
				<ShipmentInput
					value={fields.shipment}
					onChange={updateFields('shipment')}
					errors={showError && errors.shipment}
					required
				/>
				<hr />
				<DateInput
					label="Shipment Creation Date"
					value={fields.earliestShipDateTime}
					onChange={updateFields('earliestShipDateTime')}
					errors={showError && errors.earliestShipDateTime}
					includeTime
					required
				/>
				<hr />
				<ShowMoreInput
					labelShow="Show advanced options"
					labelHide="Hide advanced options"
					isOpened={fields.isShowMoreOpened}
					onChange={updateFields('isShowMoreOpened')}
				>
					<RequirementsInput
						require={fields.require}
						include={fields.include}
						onChangeInclude={updateFields('include')}
						onChangeRequire={updateFields('require')}
						requireErrors={showError && errors.require}
					/>
				</ShowMoreInput>
			</>
		)
	}
	function getRawRequest() {
		return (
			<RawRequest
				errors={showError && errors.rawRequestDeliveryDates}
				value={fields.rawRequestDeliveryDates}
				onChange={updateFields('rawRequestDeliveryDates')}
				hardcodedFields={HARDCODED_FIELDS}
			/>
		)
	}
	return (
		<>
			<RequestInputTypeSwitcher
				value={fields.requestInputType}
				onChange={value => {
					onChangeViewType()
					updateFields('requestInputType')(value)
				}}
			/>
			{fields.requestInputType === REQUEST_INPUT_TYPE.FORM ? getForm() : getRawRequest()}
		</>
	)
}
