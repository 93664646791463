import resultPaneStyles from '../ResultPane/ResultPane.module.css'
import styles from './ShipRecResult.module.css'
import _ from 'lodash'
import ResultCard from '../ResultCard/ResultCard'
import React from 'react'
import { ShippingFeasibilityError } from '../ShippingFeasibilityError/ShippingFeasibilityError'
import { shapes } from '@cimpress/react-components'
const { Robot } = shapes

const containsOnlyLatePlans = plans => !plans.find(({ transitEstimate }) => !_.get(transitEstimate, 'lateArrival'))

const hasShippingFeasibilityError = shippingFeasibilityStatus =>
	_.get(shippingFeasibilityStatus, 'status') === 'invalid'

const addMessageContainer = component => <div className={styles.messageContainer}>{component}</div>

const renderPlans = recommendationsResponse => {
	return (
		<>
			{addMessageContainer(
				<ShippingFeasibilityError shippingFeasibility={recommendationsResponse.shippingFeasibility} />,
			)}
			{recommendationsResponse.recommendations.map((plan, index) => {
				return <ResultCard key={index} plan={plan} />
			})}
		</>
	)
}

function renderFullScreen(component) {
	return <div className={`${resultPaneStyles.resultContainer} ${resultPaneStyles.centerVertically}`}>{component}</div>
}

const NO_DATA_COMPONENT = (
	<>
		<span className={resultPaneStyles.centerIcon}>
			<Robot bsStyle="warning" alternate />
		</span>
		<p className={resultPaneStyles.resultText}>
			Unfortunately, there are no shipping recommendations for what you would like to ship.
			<br />
			Try to modify the request.
		</p>
	</>
)

export default function ShipRecResult({ result: recommendationsResponse }) {
	if (_.isEmpty(recommendationsResponse.recommendations)) {
		if (hasShippingFeasibilityError(recommendationsResponse.shippingFeasibility)) {
			return (
				<>
					{NO_DATA_COMPONENT}
					<hr className={resultPaneStyles.separator} />
					{addMessageContainer(
						<ShippingFeasibilityError shippingFeasibility={recommendationsResponse.shippingFeasibility} />,
					)}
				</>
			)
		} else {
			return renderFullScreen(NO_DATA_COMPONENT)
		}
	} else if (containsOnlyLatePlans(recommendationsResponse.recommendations)) {
		return (
			<>
				<span className={resultPaneStyles.centerIcon}>
					<Robot bsStyle="warning" />
				</span>
				<p className={`${resultPaneStyles.resultText} ${styles.lateDeliveryText}`}>
					Ouch, we have estimates only for late delivery (see below).
					<br />
					If you need on time estimates, you will need to modify your request.
				</p>
				<hr className={resultPaneStyles.separator} />
				{renderPlans(recommendationsResponse)}
			</>
		)
	} else {
		return renderPlans(recommendationsResponse)
	}
}
