import React from 'react'
import { FlexBox } from '@cimpress/react-components'
import PrioritiesSorter from './PrioritiesSorter/PrioritiesSorter'

import styles from './StrategyInput.module.css'
import formStyles from '../RequestForm.module.css'

export const STRATEGY_PRIORITY = {
	PREFERENCE: {
		value: 'preference',
		name: 'Preference',
	},
	LOWEST_COST: {
		value: 'lowestCost',
		name: 'Lowest Cost',
	},
	LATEST_SHIP_DATE: {
		value: 'latestShipDate',
		name: 'Latest Ship Date',
	},
	EARLIEST_DELIVERY_DATE: {
		value: 'earliestDeliveryDate',
		name: 'Earliest Delivery Date',
	},
}

export default function StrategyInput({ value, onChange }) {
	return (
		<>
			<h5 className={formStyles.bottomMargin}>{'Estimate Priorities'}</h5>
			<FlexBox middle spaceBetween marginX="m" className={styles.requirementsOrder}>
				<PrioritiesSorter
					options={STRATEGY_PRIORITY}
					strategyPriorities={value.onTimePriorities}
					onChange={onTimePriorities => onChange({ ...value, onTimePriorities })}
					title={'ON TIME DELIVERY'}
				/>
				<PrioritiesSorter
					options={STRATEGY_PRIORITY}
					strategyPriorities={value.lateDeliveryPriorities}
					onChange={lateDeliveryPriorities => onChange({ ...value, lateDeliveryPriorities })}
					title={'LATE DELIVERY'}
				/>
			</FlexBox>
		</>
	)
}
