import _ from 'lodash'
import React, { useContext, useMemo } from 'react'
import SelectValue from '../SelectValue/SelectValue'
import FulfillmentLocationsContext from '../../context/FulfillmentLocationsContext'
import formStyles from './RequestForm.module.css'
import FulfillersNamesContext from '../../context/FulfillersNamesContext'
import { ERROR_TYPE_REQUIRED } from './utils/formErrorHandling'

const FulfillmentLocation = ({ value, onChange, required, errors }) => {
	const { fulfillmentLocations } = useContext(FulfillmentLocationsContext)
	const { fulfillersIdNameMap } = useContext(FulfillersNamesContext)
	const options = useMemo(
		() =>
			(fulfillmentLocations || []).map(({ fulfillmentLocationId, name, fulfillerId, timeZone }) => ({
				// Value for output
				value: { fulfillmentLocationId, timeZone },
				// Values for filtering
				filterValues: [fulfillmentLocationId, name, fulfillerId, fulfillersIdNameMap[fulfillerId]]
					.filter(_.identity)
					.map(_.lowerCase),
				// Label
				label: (
					<>
						{name} (<small>{fulfillmentLocationId}</small>) - {fulfillersIdNameMap[fulfillerId] || 'Missing permission'}{' '}
						(<small>{fulfillerId}</small>)
					</>
				),
			})),
		[fulfillmentLocations, fulfillersIdNameMap],
	)

	return (
		<div className={formStyles.formPart}>
			<div className={`row ${formStyles.row}`}>
				<div className="col col-xs-12">
					<h5 className={formStyles.header}>Shipping from</h5>
					<SelectValue
						className="selectBox"
						containerClassName={[formStyles.noBottomMargin, errors ? 'has-error' : ''].join(' ')}
						label="Fulfillment location"
						filterOption={(option, query) => {
							return option.data.filterValues.find(value => value.includes(query.toLowerCase()))
						}}
						value={value}
						onChange={onChange}
						options={options}
						required={required}
						isClearable
						tether
						helpText={errors && errors === ERROR_TYPE_REQUIRED ? 'Please select fulfillment location' : ''}
					/>
				</div>
			</div>
		</div>
	)
}

export default FulfillmentLocation
