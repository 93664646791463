import _ from 'lodash'

export const translateError = err => {
	let { message } = err

	if (err.response) {
		message = `${_.get(err, 'response.data.title', '')}: ${_.join(
			_.get(err, 'response.data.causes', []).map(cause => cause.detail),
			',',
		)}`
	}

	if (/Unprocessable request: Master configuration for location .* cannot be found in cache\./.test(message)) {
		return `This location does not have a planning configuration. Create the configuration or contact ${process.env.REACT_APP_SC_SUPPORT_MAIL} for support.`
	} else if (message === 'Network Error') {
		return `Network Error occurred. Try again to resolve the issue.`
	}
	return `Unknown error occurred. Contact support on ${process.env.REACT_APP_SUPPORT_MAIL} with following issue: ${message}`
}
